import React from "react";
import FeatureLayout from "../components/FeatureLayout/FeatureLayout";
import MainLayout from "../components/MainLayout";

const feature = {
  id: "interactivity",
  image: "/images/showcase/feature-interactivity.png",
  title: "Interactivity",
  description: (
    <p>
      Let your customers discover every angle of your product. <br />
      <br />
      You will leave them with no open questions — and no reasons not to buy
      your product.
    </p>
  ),
  ctaText: "Try it out",
  secondaryImage: "/images/showcase/Pie Circular.png",
  nextRoute: "/configurator",
  width: 40
};

const Interactivity = () => {
  return <FeatureLayout {...feature} />;
};

Interactivity.Layout = MainLayout;

export default Interactivity;
